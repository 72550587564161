@import "../../styles/colors";
@import "../../styles/baseStyles";

.filter-tag-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .filter-tag {
    font-size: 18px;
    margin: 25px 25px 0 0;
    padding: 10px;
    background-color: $dark-light-color;
    border-radius: $border-radius;
  }

  &.home {
    .filter-tag {
      margin: 25px 25px 0 0;
      background-color: $dark-light-color;
    }
  }

  &.detailed {
    .filter-tag {
      margin: 0 25px 25px 0;
      background-color: $light-color;
    }
  }

  &.about {
    .filter-tag {
      margin: 0 25px 25px 0;
    }
  }
}
