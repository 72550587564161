@import "../../styles/mediaQueries";
@import "../../styles/colors";

.four-oh-four-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include tablet {
    width: 100%;
    height: 100vh;
  }

  .four-oh-four {
    font-size: calc(30vw + 2rem);
    position: relative;
    transform: translate(0);
    animation: color-change 5s infinite;
    animation-delay: 4s;

    &::before {
      color: $music-library-color-1;
      content: "404";
      position: absolute;
      left: -7px;
      top: -7px;
      z-index: -1;
      animation: move-red 5s infinite;
    }

    @keyframes move-red {
      0% {
        transform: translate(0);
      }
      50% {
        transform: translate(7px, 7px);
      }
      100% {
        transform: translate(0);
      }
    }

    &::after {
      color: $gemini-color;
      content: "404";
      position: absolute;
      left: 7px;
      top: 7px;
      z-index: -1;
      animation: move-blue 5s infinite;
    }

    @keyframes move-blue {
      0% {
        transform: translate(0);
      }
      50% {
        transform: translate(-7px, -7px);
      }
      100% {
        transform: translate(0);
      }
    }

    @keyframes color-change {
      0% {
        color: rgba($dark-color, 0.5);
      }
      50% {
        color: rgba($dark-color, 1);
      }
      100% {
        color: rgba($dark-color, 0.5);
      }
    }
  }

  .return-home-link {
    margin-top: 40px;
    &.link {
      &::after {
        width: 100%;
      }

      &:hover {
        &::after {
          width: 50%;
        }
      }
    }

    &.active {
      &::after {
        width: 100%;
      }
    }
  }
}
