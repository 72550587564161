@import "../../styles/mediaQueries";
@import "../../styles/baseStyles";
@import "../../styles/colors";

.targetbase-project {
  background-color: $dark-medium-color;
  height: 600px;
  position: relative;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  overflow: hidden;

  /*	navbar */
  .logo {
    padding-top: 20px;
    margin-left: 50px;
    float: left;
  }

  .navbar {
    height: 100px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .navbar ul {
    list-style-type: none;
    padding-top: 35px;
    float: right;
    margin-right: 50px;
  }

  .navbar ul li {
    display: inline-block;
    padding-left: 30px;
    font-family: priori-sans, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    color: $light-color;
    text-decoration: none;
    position: relative;
  }
  /*	end navbar */

  h1 {
    color: $targetbase-color;
    font-size: 72px;
    font-family: priori-sans, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    line-height: 0.9;
  }

  .section {
    background-color: $dark-medium-color;
    width: 100%;
    height: 100%;
  }

  .hero-text {
    position: absolute;
    top: calc(50% - 80px);
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;

    @include smallScreen {
      top: 50%;
    }
  }

  .hero-text::after {
    content: "";
    width: 50%;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: -20px;
    position: absolute;
    border-bottom: 10px solid $targetbase-color;
  }

  #hero-image {
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .data-icon {
    position: absolute;
    right: -35%;
    top: 40%;
    animation: rotation 90s linear infinite;

    @include smallScreen {
      right: -35%;
      top: -10%;
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
