@import "../../styles/baseStyles";
@import "../../styles/colors";
@import "../../styles/mediaQueries";

.main-container {
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 30%);
  background-color: #fff;
  margin-bottom: 25px;

  &.preview__container {
    height: 0;
    min-height: 200px;
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;

    .responsive-carousel__showcase-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin-bottom: 25px;
    }
  }

  @include tablet {
    min-width: 400px;
    margin-bottom: 0;
  }

  .carousel-item {
    h1,
    h2,
    h3 {
      color: #252525;
      margin: 40px;
    }

    .carousel-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .add-anything__item {
      margin: 40px 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h3 {
        align-self: flex-end;
      }
    }
  }
}
