$light-color: #fff;
$dark-light-color: #d1d1d1;
$dark-medium-color: #333;
$dark-color: #000;
$nominapress-color: #233869;
$random-shapes-color: #c672cc;
$gemini-color: #0fa5a1;
$targetbase-color: #ffb902;
$basf-color: #004a96;
$responsive-carousel-color: #da8e74;
$about-color: #14402e;

$music-library-color-1: #e85757;
$music-library-color-2: #71ccbe;
$music-library-color-3: #c672cc;
$music-library-color-4: #e5d1c1;

:export {
  lightColor: $light-color;
  darkLightColor: $dark-light-color;
  darkMediumColor: $dark-medium-color;
  darkColor: $dark-color;
  nominapressColor: $nominapress-color;
  randomShapesColor: $random-shapes-color;
  geminiColor: $gemini-color;
  targetbaseColor: $targetbase-color;
  basfColor: $basf-color;
  responsiveCarouselColor: $responsive-carousel-color;
  aboutColor: $about-color;
  musicLibraryColor1: $music-library-color-1;
  musicLibraryColor2: $music-library-color-2;
  musicLibraryColor3: $music-library-color-3;
  musicLibraryColor4: $music-library-color-4;
}
