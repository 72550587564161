@import "../../styles/mediaQueries";
@import "../../styles/baseStyles";
@import "../../styles/colors";

.about-container {
  .about-container-grid {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    margin: 0 0 25px 0;

    @include tablet {
      grid-gap: 50px;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: auto;
      margin: 130px 50px 50px;
    }

    @include smallScreen {
      grid-template-columns: 1fr 1fr;
    }

    .about-grid-item {
      border-radius: $border-radius;

      &.headshot {
        width: 100%;
        min-width: 300px;
        height: auto;
        min-height: 500px;
        box-shadow: $box-shadow;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        overflow: hidden;
        transform: scaleX(-1);
      }

      &.about {
        padding-top: 25px;
        color: $dark-color;

        @include tablet {
          padding-top: 0;
        }

        .about-paragraph {
          .work-link-text {
            display: inline-block;

            .current-client-link {
              color: $light-color;
              padding: 1px 5px;
              background-color: $basf-color;
              border-radius: 5px;
              position: relative;

              .current-client-tooltip-container {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 30px;
                background-color: $basf-color;
                padding: 20px;
                border-radius: $border-radius;
                transition: 0.3s ease-in-out;

                &::after {
                  content: "";
                  position: absolute;
                  bottom: -10px;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 0;
                  height: 0;
                  border-left: 15px solid transparent;
                  border-right: 15px solid transparent;
                  border-top: 15px solid $basf-color;
                }

                .current-client-tooltip {
                  .current-client-tooltip-img {
                    width: 300px;
                    height: auto;
                    border-radius: $border-radius;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
