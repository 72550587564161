@import "../../styles/mediaQueries";
@import "../../styles/baseStyles";
@import "../../styles/colors";

#navbar {
  width: 100%;
  position: relative;
  top: 0;
  overflow: hidden;
  z-index: 10;

  @include tablet {
    position: fixed;
  }

  .navbar-content {
    margin: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include tablet {
      margin: 25px 50px;
    }

    .logo {
      font-size: 36px;
      padding: 5px 10px;
      border-radius: $border-radius;
      position: relative;
      transition: 0.3s ease-in-out;
    }

    .nav-items-container {
      display: flex;
      align-items: center;
      padding: 5px 10px;
      border-radius: $border-radius;
      background-color: $light-color;

      .nav-item {
        font-size: 18px;

        &:not(:first-child) {
          margin-left: 20px;
        }

        &.nav-link {
          &.link {
            &::after {
              width: 0;
            }

            &:hover {
              &::after {
                width: 100%;
              }
            }
          }

          &.active {
            &::after {
              width: 100%;
            }
          }
        }

        &.btn-container {
          position: fixed;
          right: 25px;
          bottom: 25px;

          @include tablet {
            position: relative;
            right: 0;
            bottom: 0;
          }

          .translation-btn {
            color: $dark-color;
            font-size: 18px;
            padding: 10px;
            background-color: $light-color;
            border: 2px solid $dark-color;
            transition: 0.3s ease-in-out;
            cursor: pointer;

            &:first-of-type {
              border-radius: $border-radius 0 0 $border-radius;

              &.active {
                box-shadow: -3px 0 3px rgba($dark-color, 0.3);
              }
            }

            &:last-of-type {
              border-radius: 0 $border-radius $border-radius 0;
              margin-left: -2px;

              &.active {
                box-shadow: 3px 0 3px rgba($dark-color, 0.3);
              }
            }

            &:hover {
              background-color: $dark-light-color;
            }

            &.active {
              background-color: $dark-color;
              color: $light-color;
            }

            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
}
