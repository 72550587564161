.active-hover {
  .safe-icon {
    .turn-handle {
      transition: 0.5s ease-in-out;
      animation: rotate 1.5s step-start infinite;
    }

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(-90deg) translate(-70px, -33px);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }
}
