@import "../../styles/mediaQueries";
@import "../../styles/baseStyles";
@import "../../styles/colors";

.random-shapes__container {
  width: 100%;
  min-width: 100%;
  padding-top: 56.25%;
  position: relative;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  overflow: hidden;
  background-color: $light-color;
  margin-bottom: 25px;

  @include tablet {
    min-width: 400px;
    margin-bottom: 0;
  }
}
