@import "../../../styles/baseStyles";
@import "../../../styles/colors";

.social-links-container {
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: $about-color;
  border-radius: $border-radius;
  margin-top: 25px;
  position: relative;

  .social-link {
    color: $light-color;
    font-size: 24px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s ease-in-out;
    border-radius: $border-radius;

    &:hover {
      background-color: rgba($light-color, 0.2);
    }
  }
}
