@import "../../../../../../styles/colors";

.active-hover {
  .tech-icon {
    .blink {
      transition: 0.3s ease-in-out;
      animation: blink 2s step-start 0s infinite;

      &.blink-1 {
        animation-delay: 0.5s;
      }

      &.blink-2 {
        animation-delay: 1s;
      }

      &.blink-3 {
        animation-delay: 1.5s;
      }

      &.blink-4 {
        animation-delay: 2s;
      }
    }

    @keyframes blink {
      0% {
        opacity: 1;
        fill: $light-color;
      }
      50% {
        opacity: 0.3;
        fill: $dark-color;
      }
      100% {
        opacity: 1;
        fill: $light-color;
      }
    }
  }
}
