@import "../../styles/colors";
@import "../../styles/mediaQueries";

.gridline-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: -1;

  @include tablet {
    height: 100vh;
    flex-direction: row;
    position: fixed;
  }

  .gridline {
    width: 100%;
    height: auto;
    border-right: none;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include tablet {
      flex-direction: row;
      width: 25%;
      height: auto;
      border-right: 1px solid $dark-light-color;
    }
  }

  .gridline:last-child {
    border-right: none;
  }
}
