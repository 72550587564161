@import "../../styles/mediaQueries";
@import "../../styles/baseStyles";
@import "../../styles/colors";

#color-column {
  transition: background-color 0.5s ease-in-out;
}

.projects-container {
  transition: transform 0.25s;
  will-change: transform;

  .content-grid {
    height: auto;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &:not(:first-of-type) {
      margin: 100px 0;
    }

    @include tablet {
      height: 100vh;
      flex-direction: row;

      &:not(:first-of-type) {
        margin: 0;
      }
    }

    .content {
      width: 100%;
      margin-top: 0;

      @include tablet {
        width: 50%;
        margin: 0 50px;
      }

      .project-link {
        float: left;
        margin-top: 50px;

        &.link {
          &::after {
            width: 100%;
          }

          &:hover {
            &::after {
              width: 50%;
            }
          }
        }
      }
    }

    #col-right {
      padding-bottom: 100px;
      border-bottom: 1px solid $dark-light-color;

      @include tablet {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
}

.proj-img {
  width: 100%;
  min-width: 100%;
  height: auto;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  margin-bottom: 25px;

  @include tablet {
    min-width: 400px;
    margin-bottom: 0;
  }
}
