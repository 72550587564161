@import "./reset";
@import "./mediaQueries";
@import "./colors";
@import "./baseStyles";

body {
  font-family: "Menlo", -apple-system, sans-serif;
}

.page-content {
  margin: 25px;

  @include tablet {
    margin: 0;
  }
}

h1 {
  font-size: 36px;
  font-weight: 400;
}

.link {
  font-size: 18px;
  position: relative;
  transition: 0.3s ease-in-out;

  &::after {
    content: "";
    position: absolute;
    bottom: -7px;
    left: 0;
    height: 2px;
    background-color: $dark-color;
    transition: 0.3s ease-in-out;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.max-width {
  max-width: 1200px;
  margin: 0 auto 100px auto;
  display: block;

  @include tablet {
    margin: 0 auto;
  }
}
