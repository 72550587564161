@import "../../../../styles/mediaQueries";
@import "../../../../styles/baseStyles";
@import "../../../../styles/colors";

.gemini-container {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  overflow: hidden;
}

.gemini-nav-bar {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  position: relative;

  .logo {
    margin-left: 20px;
    position: absolute;
    left: 0;
    top: 20px;

    @include smallScreen {
      margin-left: 40px;
      position: relative;
      top: auto;
    }
  }

  ul {
    width: 100%;
    visibility: hidden;

    @include smallScreen {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: flex-end;
      visibility: visible;
    }

    li {
      margin-left: 0;
      color: $dark-color;
      font-size: 48px;
      font-weight: 400;
      font-style: normal;
      opacity: 0.9;

      @include smallScreen {
        margin-left: 40px;
        font-size: 24px;
      }
    }

    #quote {
      color: $light-color;
      font-weight: 700;
    }

    #quote {
      padding: 0 40px;
      position: relative;
    }

    #quote::before {
      content: "";
      width: 100%;
      height: auto;
      background-color: $gemini-color;
      box-shadow: $box-shadow;
      border-radius: 0 0 0 10px;
      position: absolute;
      top: -37px;
      right: 0;
      z-index: -1;

      @include smallScreen {
        height: 600px;
      }
    }
  }

  .nav-bar-icon {
    position: absolute;
    right: 20px;
    top: 34px;
    visibility: visible;

    @include smallScreen {
      visibility: hidden;
    }
  }
}

.nav-bar.responsive {
  background-color: $light-color;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}

.nav-bar-ul.responsive {
  text-align: center;
  visibility: visible;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.nav-bar-icon.responsive {
  opacity: 0;
}

.gemini-hero {
  width: 100%;
  height: auto;
  font-family: priori-sans, sans-serif;
  z-index: 2;

  @include smallScreen {
    height: calc(600px - 100px);
  }

  .gemini-hero-container {
    margin: 0 20px 20px 20px;
    position: relative;

    @include smallScreen {
      margin: 0 40px 40px 40px;
    }
  }

  .gemini-hero-img {
    height: calc(600px - 120px);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    min-height: 400px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    position: relative;

    @include smallScreen {
      height: calc(600px - 140px);
    }

    .gemini-hero-img-content-container {
      width: 80%;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      @include smallScreen {
        margin: 40px;
        left: 0;
        top: auto;
        bottom: 20px;
        transform: none;
        text-align: left;
      }

      h1 {
        font-size: 60px;
        font-weight: 700;
        color: $light-color;
        line-height: 0.88;
        margin-bottom: 40px;
      }

      .gemini-btn {
        color: $light-color;
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        background-color: transparent;
        border: 2px solid $gemini-color;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        padding: 10px;
        transition: 0.3s ease-in-out;

        @include smallScreen {
          font-size: 36px;
          padding: 20px;
        }
      }
    }
  }
}
